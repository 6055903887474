<template>
    <perfect-scrollbar
            class="quick-search-wrapper scroll"
            style="max-height: 40vh; position: relative"
            v-bind:options="{ suppressScrollX: true }"
    >
        <div class="quick-search-result">
            <!--begin::Message-->
            <div
                    v-if="!data.products.length && !data.sizes.length && !data.tags.length"
                    class="text-muted"
            >
                {{ $t("SEARCH.NO_RESULTS") }}
            </div>
            <!--end::Message-->

            <div
                    v-if="data.products.length"
                    class="font-size-sm text-primary font-weight-bolder text-uppercase mb-2"
            >
                {{ $t("PRODUCT.PRODUCTS") }}
            </div>
            <template v-for="product in data.products">
                <div
                        :key="product.id"
                        class="d-flex align-items-center flex-grow-1 mb-2"
                >
                    <div class="d-flex flex-column ml-3 mt-2 mb-2">
                        <router-link
                                :to="{ name: 'product', params: { id: product.id } }"
                                class="font-weight-bold text-dark text-hover-primary"
                        >
                            {{ product.name }}
                        </router-link>
                        <div class="font-size-sm font-weight-bold text-muted">
                            {{ product.sku }}
                        </div>
                    </div>
                </div>
            </template>
            <div
                    v-if="data.sizes.length"
                    class="font-size-sm text-primary font-weight-bolder text-uppercase mb-2"
            >
                {{ $t("SIZE.SIZES") }}
            </div>
            <template v-for="size in data.sizes">
                <div :key="size.id" class="d-flex align-items-center flex-grow-1 mb-2">
                    <div v-if="size.color_image" class="symbol symbol-30 flex-shrink-0">
                        <img
                                class="symbol-label"
                                :src="size.color_image"
                                style="width: auto"
                                alt=""
                        />
                    </div>
                    <div class="d-flex flex-column ml-3 mt-2 mb-2">
                        <router-link
                                :to="{ name: 'size', params: { id: size.id } }"
                                class="font-weight-bold text-dark text-hover-primary"
                        >
                            {{ size.name }}
                        </router-link>
                        <div class="font-size-sm font-weight-bold text-muted">
                            {{ size.product_name }}
                        </div>
                    </div>
                </div>
            </template>
              <div v-if="data.tags.length"
                                            class="font-size-sm text-primary font-weight-bolder text-uppercase mb-2"
                                    >
                                        {{ $t("TAG.TAGS") }}
                                    </div>
                         <template v-for="tag in data.tags">
                                        <div :key="tag.uid" class="d-flex align-items-center flex-grow-1 mb-2">

                                            <div class="d-flex flex-column ml-3 mt-2 mb-2">
                                                <router-link
                                                        :to="{ name: 'tag', params: { uid: tag.uid } }"
                                                        class="font-weight-bold text-dark text-hover-primary"
                                                >
                                                    {{ tag.uid }}
                                                </router-link>
                                                <div class="font-size-sm font-weight-bold text-muted">
                                                    {{ tag.uid }}
                                                </div>
                                            </div>
                                        </div>
                                    </template>
        </div>
    </perfect-scrollbar>
</template>

<script>
    export default {
        name: "KTSearchResult",
        props: {
            data: {
                products: [],
                sizes: [],
            },
        },
    };
</script>
