<template>
    <div
            v-bind:class="{ 'quick-search-has-result': result }"
            class="quick-search quick-search-dropdown quick-search-result-compact"
            id="kt_quick_search_dropdown"
    >
        <form method="get" class="quick-search-form">
            <div
                    class="input-group spinner-input spinner-sm spinner-brand spinner-right"
                    v-bind:class="{ spinner: loading }"
            >
                <div class="input-group-prepend">
          <span class="input-group-text">
            <i class="flaticon2-search-1"></i>
          </span>
                </div>
                <input
                        v-on:keyup="onSearch"
                        type="text"
                        ref="searchterm"
                        class="form-control quick-search-input"
                        :placeholder="$t('SEARCH.PLACEHOLDER')"
                />
                <div class="input-group-append">
          <span class="input-group-text">
            <i
                    class="flaticon2-cross text-hover-primary cursor-pointer"
                    v-on:click="reset()"
                    v-bind:style="{ display: result && !loading ? 'flex' : 'none' }"
            ></i>
          </span>
                </div>
            </div>
        </form>

        <KTSearchResult v-bind:data="data"></KTSearchResult>
    </div>
</template>

<script>
    import KTSearchResult from "@/view/layout/extras/dropdown/SearchResult.vue";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "KTSearchDefault",
        components: {KTSearchResult},
        data() {
            return {
                data: {
                    products: [],
                    sizes: [],
                    tags: [],
                },
                loading: false,
                result: false,
            };
        },
        methods: {
            onSearch(event) {
                if (event.target.value.length > 2) {
                    this.loading = true;
                    ApiService.post("search", {term: event.target.value})
                        .then((result) => {
                            this.result = true;
                            this.data = result.data;
                            this.loading = false;
                        })
                        .catch(() => {
                            this.loading = false;
                            this.result = false;
                        });
                }
            },
            reset() {
                this.$refs.searchterm.value = "";
                this.data = {
                    products: [],
                    sizes: [],
                    tags: [],
                };
                this.result = false;
            },
        },
    };
</script>
