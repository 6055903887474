<template>
    <!-- begin:: Footer -->
    <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
        <div
            class="d-flex align-items-center justify-content-between"
            v-bind:class="{
                'container-fluid': widthFluid,
                container: !widthFluid,
            }"
        >
            <div class="text-dark">
                <span class="text-muted font-weight-bold mr-2">
                    {{ new Date().getFullYear() }} &nbsp;&copy;&nbsp;
                </span>
                <a
                    href="https://xiphoo.com/"
                    rel="noopener"
                    target="_blank"
                    class="text-dark-75 text-hover-primary"
                >
                    Xiphoo GmbH
                </a>
            </div>
            <div class="nav nav-dark">
                <span
                    v-if="checkStage()"
                    class="label label-lg label-inline label-light-warning mr-2"
                    >{{ $t("STAGING_ENVIRONMENT") }}</span
                >
                <span
                    v-if="checkDev()"
                    class="label label-lg label-inline label-light-success mr-2"
                    >{{ $t("DEVELOPMENT_ENVIRONMENT") }}</span
                >
                <span
                    v-if="currentUser.admin"
                    class="label label-lg label-inline label-light-danger"
                    >{{ $t("AUTH.ADMIN") }}</span
                >
            </div>
        </div>
    </div>
    <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex"

export default {
    name: "KTFooter",
    computed: {
        ...mapGetters(["layoutConfig", "currentUser"]),

        /**
         * Check if footer container is fluid
         */
        widthFluid() {
            return this.layoutConfig("footer.width") === "fluid"
        },
    },
    methods: {
        checkDev() {
            return process.env?.NODE_ENV === "development"
        },
        checkStage() {
            return process.env?.NODE_ENV === "staging"
        },
    },
}
</script>
