<template>
    <ul class="menu-nav">
        <li class="menu-section mt-0">
            <h4 class="menu-text">{{ $t("BRAND.BRAND") }}</h4>
            <i class="menu-icon flaticon-more-v2"></i>
        </li>
        <router-link
            to="/about"
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                ]"
            >
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon2-information">
                        <span></span>
                    </i>
                    <span class="menu-text">{{ $t("ABOUT.ABOUT") }}</span>
                </a>
            </li>
        </router-link>

        <router-link
            to="/users"
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                ]"
            >
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon2-user">
                        <span></span>
                    </i>
                    <span class="menu-text">{{ $t("USER.USERS") }}</span>
                </a>
            </li>
        </router-link>

        <li class="menu-section">
            <h4 class="menu-text">{{ $t("PRODUCT.PRODUCTS") }}</h4>
            <i class="menu-icon flaticon-more-v2"></i>
        </li>

        <router-link
            to="/products"
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                ]"
            >
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon2-box-1"></i>
                    <span class="menu-text">{{
                        $t("PRODUCT.MANAGEMENT")
                    }}</span>
                </a>
            </li>
        </router-link>

        <router-link
            to="/importer"
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                ]"
            >
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon2-layers-1"></i>
                    <span class="menu-text">{{ $t("IMPORTER.IMPORTER") }}</span>
                </a>
            </li>
        </router-link>

        <router-link
            to="/qrcharge"
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                ]"
            >
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon2-expand"></i>
                    <span class="menu-text">{{ $t("PRODUCT.QRCODE") }}</span>
                </a>
            </li>
        </router-link>

        <router-link
            v-if="currentUser.product_page"
            to="/product_pages"
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                ]"
            >
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon2-website"></i>
                    <span class="menu-text">{{
                        $t("PRODUCT_PAGES.TITLE")
                    }}</span>
                </a>
            </li>
        </router-link>

        <li class="menu-section">
            <h4 class="menu-text">{{ $t("MANUFACTURER.MANUFACTURER") }}</h4>
            <i class="menu-icon flaticon-more-v2"></i>
        </li>
        <router-link
            to="/manufacturers"
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                ]"
            >
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon2-expand"></i>
                    <span class="menu-text">{{
                        $t("MANUFACTURER.MANUFACTURERLIST")
                    }}</span>
                </a>
            </li>
        </router-link>

        <li class="menu-section">
            <h4 class="menu-text">{{ $t("REPORTS.REPORTS") }}</h4>
            <i class="menu-icon flaticon-more-v2"></i>
        </li>

        <router-link
            to="/statistics"
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                ]"
            >
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon2-analytics-2"></i>
                    <span class="menu-text">{{
                        $t("REPORTS.STATISTICS")
                    }}</span>
                </a>
            </li>
        </router-link>

        <li class="menu-section" v-if="currentUser.admin">
            <h4 class="menu-text">{{ $t("ADMINISTRATION") }}</h4>
            <i class="menu-icon flaticon-more-v2"></i>
        </li>

        <router-link
            v-if="currentUser.admin"
            to="/brands"
            v-slot="{ href, navigate, isActive, isExactActive }"
        >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                ]"
            >
                <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon2-world">
                        <span></span>
                    </i>
                    <span class="menu-text">{{ $t("BRAND.BRANDS") }}</span>
                </a>
            </li>
        </router-link>
    </ul>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    name: "KTMenu",
    methods: {
        hasActiveChildren(match) {
            return this.$route["path"].indexOf(match) !== -1
        },
    },
    computed: {
        ...mapGetters(["currentUser"]),
    },
}
</script>
