<template>
    <!-- begin:: Header -->
    <div
            id="kt_header"
            ref="kt_header"
            class="header"
            v-bind:class="headerClasses"
    >
        <div
                class="container-fluid d-flex align-items-center justify-content-between"
        >
            <!-- begin:: Header Menu -->
            <div
                    class="header-menu-wrapper header-menu-wrapper-left"
                    ref="kt_header_menu_wrapper"
            >
                <router-link
                        to="/about"
                        class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
                >
                    <div
                            class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                    >
                        <div v-if="currentUser.logo" class="symbol">
                            <img
                                    :src="currentUser.logo"
                                    style="
                  width: auto;
                  height: auto;
                  max-width: 70px;
                  max-height: 35px;
                "
                            />
                        </div>
                        <span
                                class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline"
                                :class="currentUser.logo && 'ml-3'"
                        >{{ currentUser.brand }}</span
                        >
                    </div>
                </router-link>
            </div>
            <!-- end:: Header Menu -->
            <KTTopbar></KTTopbar>
        </div>
    </div>
    <!-- end:: Header -->
</template>

<script>
    import {mapGetters} from "vuex";
    import KTTopbar from "@/view/layout/header/Topbar.vue";
    import KTLayoutHeader from "@/assets/js/layout/base/header.js";
    import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";

    export default {
        name: "KTHeader",
        components: {
            KTTopbar,
        },
        mounted() {
            // Init Desktop & Mobile Headers
            KTLayoutHeader.init("kt_header", "kt_header_mobile");

            // Init Header Menu
            KTLayoutHeaderMenu.init(
                this.$refs["kt_header_menu"],
                this.$refs["kt_header_menu_wrapper"]
            );
        },
        computed: {
            ...mapGetters(["layoutConfig", "getClasses", "currentUser"]),

            /**
             * Check if the header menu is enabled
             * @returns {boolean}
             */
            headerMenuEnabled() {
                return !!this.layoutConfig("header.menu.self.display");
            },

            /**
             * Get extra classes for header based on the options
             * @returns {null|*}
             */
            headerClasses() {
                const classes = this.getClasses("header");
                if (typeof classes !== "undefined") {
                    return classes.join(" ");
                }
                return null;
            },

            /**
             * Get extra classes for header menu based on the options
             * @returns {null|*}
             */
            headerMenuClasses() {
                const classes = this.getClasses("header_menu");
                if (typeof classes !== "undefined") {
                    return classes.join(" ");
                }
                return null;
            },
        },
    };
</script>
