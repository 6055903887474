var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_c('li',{staticClass:"menu-section mt-0"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("BRAND.BRAND")))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]),_c('router-link',{attrs:{"to":"/about"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-information"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ABOUT.ABOUT")))])])])]}}])}),_c('router-link',{attrs:{"to":"/users"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-user"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("USER.USERS")))])])])]}}])}),_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("PRODUCT.PRODUCTS")))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]),_c('router-link',{attrs:{"to":"/products"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-box-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("PRODUCT.MANAGEMENT")))])])])]}}])}),_c('router-link',{attrs:{"to":"/importer"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-layers-1"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("IMPORTER.IMPORTER")))])])])]}}])}),_c('router-link',{attrs:{"to":"/qrcharge"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-expand"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("PRODUCT.QRCODE")))])])])]}}])}),(_vm.currentUser.product_page)?_c('router-link',{attrs:{"to":"/product_pages"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-website"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("PRODUCT_PAGES.TITLE")))])])])]}}],null,false,4182846203)}):_vm._e(),_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MANUFACTURER.MANUFACTURER")))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]),_c('router-link',{attrs:{"to":"/manufacturers"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-expand"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("MANUFACTURER.MANUFACTURERLIST")))])])])]}}])}),_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("REPORTS.REPORTS")))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]),_c('router-link',{attrs:{"to":"/statistics"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-analytics-2"}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("REPORTS.STATISTICS")))])])])]}}])}),(_vm.currentUser.admin)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("ADMINISTRATION")))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.currentUser.admin)?_c('router-link',{attrs:{"to":"/brands"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-world"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("BRAND.BRANDS")))])])])]}}],null,false,76286142)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }