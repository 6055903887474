<template>
    <div class="topbar-item">
        <div
                class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                id="kt_quick_user_toggle"
        >
      <span
              class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ getFullName }}
      </span>
            <span class="symbol symbol-35 symbol-light-primary">
        <span class="symbol-label font-size-h5 font-weight-bold">
          {{ getInitial }}
        </span>
      </span>
        </div>

        <div
                id="kt_quick_user"
                ref="kt_quick_user"
                class="offcanvas offcanvas-right p-10"
        >
            <!--begin::Header-->
            <div
                    class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
            >
                <h3 class="font-weight-bold m-0">
                    {{ $t("AUTH.LOGGED_IN_AS") }}
                </h3>
                <a
                        @click="closeOffcanvas"
                        class="btn btn-xs btn-icon btn-light btn-hover-primary"
                        id="kt_quick_user_close"
                >
                    <i class="ki ki-close icon-xs text-muted"></i>
                </a>
            </div>
            <!--end::Header-->

            <!--begin::Content-->

            <!--begin::Header-->
            <div class="d-flex align-items-center mt-5">
                <div class="d-flex flex-column">
                    <router-link
                            :to="'/user/' + currentUser.sub"
                            class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
                    >
                        {{ getFullName }}
                    </router-link>
                    <span
                            class="text-dark-75 font-weight-regular d-block font-size-md mr-2"
                    >{{ currentUser.brand }}</span
                    >
                    <div class="text-muted mt-1">{{ currentUser.function }}</div>
                    <div class="navi mt-2">
                        <a class="navi-item">
              <span class="navi-link p-0 pb-2">
                <span class="navi-icon mr-1">
                  <span class="svg-icon svg-icon-lg svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Communication/Mail.svg"/>
                      <!--end::Svg Icon-->
                  </span>
                </span>
                <span class="navi-text text-muted">
                  {{ currentUser.email }}
                </span>
              </span>
                        </a>
                    </div>
                    <button class="btn btn-primary btn-bold mt-5" @click="onLogout">
                        {{ $t("AUTH.LOGOUT") }}
                    </button>
                </div>
            </div>
            <!--end::Header-->
            <!--end::Content-->
        </div>
    </div>
</template>

<style lang="scss" scoped>
    #kt_quick_user {
        overflow: hidden;
    }
</style>

<script>
    import {mapGetters} from "vuex";
    import {LOGOUT} from "@/core/services/store/auth.module";
    import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
    import KTOffcanvas from "@/assets/js/components/offcanvas.js";

    export default {
        name: "KTQuickUser",
        mounted() {
            // Init Quick User Panel
            KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
        },
        methods: {
            onLogout() {
                this.$store
                    .dispatch(LOGOUT)
                    .then(() => this.$router.push({name: "login"}));
            },
            closeOffcanvas() {
                new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
            },
        },
        computed: {
            ...mapGetters(["currentUser"]),

            getFullName() {
                return this.currentUser.first_name + " " + this.currentUser.last_name;
            },
            getInitial() {
                if (this.currentUser.first_name) {
                    return this.currentUser.first_name.charAt(0).toUpperCase();
                }
                return null;
            },
        },
    };
</script>
